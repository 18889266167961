import { Injectable } from '@angular/core'
import { environment as env } from 'src/environments/environment'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import {
	PrimaryKey,
	UpdateCheckinDto,
	UpdateVenueConfigRoot,
	UpdatePaymentDto,
	UpdateTypesDto,
	VenuesConfig,
	UpdateImagesDto,
} from './venues-config.interfaces'

@Injectable({ providedIn: 'root' })
export class VenuesConfigApi {
	private root = 'venuesConfig'
	private baseURL = `${env.channelsAPI.baseURL}${this.root}`
	constructor(private readonly http: HttpClient) {}

	create(primaryKey: PrimaryKey) {
		return this.http.post(`${this.baseURL}`, primaryKey)
	}

	updateVenueConfigRoot(updateVenueConfigRoot: UpdateVenueConfigRoot) {
		return this.http.patch(`${this.baseURL}/updateVenueConfigRoot`, updateVenueConfigRoot)
	}

	updatePayment(updatePaymentDto: UpdatePaymentDto) {
		return this.http.patch(`${this.baseURL}/updatePayment`, updatePaymentDto)
	}

	getImagesFromBradesco(primaryKey: PrimaryKey) {
		return this.http.patch(`${this.baseURL}/getImagesFromBradesco`, primaryKey)
	}

	updateImages(updateImages: UpdateImagesDto) {
		return this.http.patch(`${this.baseURL}/updateImages`, updateImages)
	}

	updateCheckin(updateCheckinDto: UpdateCheckinDto) {
		return this.http.patch(`${this.baseURL}/updateCheckin`, updateCheckinDto)
	}

	updateTypes(updateTypesDto: UpdateTypesDto) {
		return this.http.patch(`${this.baseURL}/updateTypes`, updateTypesDto)
	}

	importAdminUsers(venueId: string) {
		return this.http.post(`${this.baseURL}/importAdminUsers`, { venueId })
	}

	importVenueNames(venueId: string) {
		return this.http.post(`${this.baseURL}/updateVenueNames`, { venueId })
	}

	findOne(primaryKey: PrimaryKey) {
		return this.http.get<VenuesConfig>(`${this.baseURL}/${primaryKey.venueId}/${primaryKey.partnerId}`)
	}

	isEnabled(primaryKey: PrimaryKey) {
		return this.http.get<Pick<VenuesConfig, 'enabled'>>(
			`${this.baseURL}/${primaryKey.venueId}/${primaryKey.partnerId}/isEnabled`
		)
	}

	uploadImage(body: { imageBase64: string }) {
		const formData = new FormData()
		formData.append('file', body.imageBase64)
		const headers = new HttpHeaders()
		headers.append('Content-Type', 'multipart/form-data')
		return this.http.post<any>(`${this.baseURL}/uploadImage`, body, { headers })
	}

	updateEmails(primaryKey: PrimaryKey, emails: any) {
		return this.http.patch(`${this.baseURL}/updateVenueConfigRoot`, {
			venueId: primaryKey.venueId,
			partnerId: primaryKey.partnerId,
			emails,
		})
	}

	updateMichelinGuide(venueId: string, michelinGuide: string) {
		return this.http.patch(`${this.baseURL}/updateMichelinGuideAllPartners`, {
			venueId,
			michelinGuide,
		})
	}
}
