<div class="page">
	<div class="page__wrapper wrapper">
		<app-back-button [page]="'report'"></app-back-button>
	</div>
	<div class="page__body">
		<div class="page__wrapper wrapper">
			<div class="report-export">
				<div class="report-export__title">{{ selectedOption.name }}</div>
				<div class="spacer"></div>
				<form class="report-export__form" [formGroup]="form" (ngSubmit)="handleDownloadCsv()">
					<div class="report-export__form__filter" *ngIf="filterCardAndRest">
						<label *ngFor="let item of tabRestAndCard" class="report-export__form__filter__label">
							<input
								ngModel
								type="radio"
								id="action"
								value="{{ item.id }}"
								class="report-export__form__filter__input"
								name="venue"
							/>
							<div class="report-export__form__filter__box w151">
								<p class="report-export__form__filter__text">
									{{ item.title }}
								</p>
							</div>
						</label>
					</div>
					<ng-container *ngIf="datePeriod">
						<div class="field__label">
							Período Inicial
							<div class="field__wrapper">
								<i class="field__icon left tagmeicon tagmeicon-calendario-24"></i>
								<input
									class="field__input"
									type="date"
									formControlName="startDate"
									name="startDate"
									placeholder="dd/mm/YYYY"
								/>
							</div>
						</div>
						<span class="field__feedback"></span>
						<div class="field__label">
							Período Final
							<div class="field__wrapper">
								<i class="field__icon left tagmeicon tagmeicon-calendario-24"></i>
								<input
									class="field__input"
									type="date"
									placeholder="dd/mm/YYYY"
									formControlName="endDate"
									name="endDate"
								/>
							</div>
						</div>
						<ng-container *ngIf="selectedOption.slug === 'emissao-gift'">
							<div class="field__label" style="position: relative">
								Venue
								<div class="field__wrapper">
									<i class="field__icon left tagmeicon tagmeicon-selo-menu-casual"></i>
									<input
										class="field__input"
										type="text"
										placeholder="Venue"
										formControlName="venueName"
										name="venueName"
										[formControl]="searchControl"
									/>
								</div>
								<ul *ngIf="hasVenues" class="venueList">
									<li
										*ngFor="let venue of venues"
										(click)="selectVenue(venue)"
										class="venueList__item"
									>
										{{ venue.name }}
									</li>
								</ul>
							</div>
							<!--
							<div class="field__label">
								Lote/Campanha
								<div class="field__wrapper">
									<i class="field__icon left tagmeicon tagmeicon-voucher"></i>
									<input
										class="field__input"
										type="text"
										placeholder="Lote/Campanha"
										formControlName="lote"
										name="lote"
									/>
								</div>
							</div>
						-->
						</ng-container>

						<ng-container *ngIf="selectedOption.slug === 'nota-fiscal-venue'">
							<div class="report-export__form__filter">
								<label class="report-export__form__filter__label" *ngFor="let item of filter">
									<input
										formControlName="filterDate"
										type="radio"
										[value]="item.value"
										class="report-export__form__filter__input"
									/>
									<div class="report-export__form__filter__box">
										<p class="report-export__form__filter__text">{{ item.title }}</p>
									</div>
								</label>
							</div>
						</ng-container>
						<span class="field__feedback"></span>

						<div style="display: flex; gap: 16px">
							<button
								*ngIf="selectedOption.slug === 'nota-fiscal-venue'"
								class="report-export__form__button button"
								type="button"
								(click)="handlePreviewJson()"
								[disabled]="status === 'pending'"
								[ngClass]="status === 'pending' ? 'disabled' : ''"
							>
								Pré-visualizar relatório
							</button>

							<button
								*ngIf="selectedOption.slug != 'emissao-gift'"
								class="report-export__form__button button"
								type="submit"
								[disabled]="status === 'pending'"
								[ngClass]="status === 'pending' ? 'disabled' : ''"
							>
								Download relatório
							</button>
							<button
								*ngIf="selectedOption.slug === 'emissao-gift'"
								class="report-export__form__button button"
								type="button"
								(click)="handleReportGift()"
							>
								Procurar Gifts nesse intervalo
							</button>
							<button
								*ngIf="selectedOption.slug === 'emissao-gift'"
								class="report-export__form__button button"
								type="button"
								[disabled]="status === 'pending'"
								[ngClass]="status === 'pending' ? 'disabled' : ''"
								(click)="handleDownloadGiftCsv()"
							>
								Download relatório
							</button>
						</div>
					</ng-container>
				</form>
			</div>

			<button
				class="report-export__form__button button"
				style="margin: 25px 0"
				*ngIf="reportFiltered !== null && reportFiltered.length !== 0"
				(click)="openModalEmail()"
			>
				Enviar e-mails
			</button>

			<div style="border: 1px solid black; border-radius: 8px; padding: 20px" *ngIf="emailSendResult">
				<div *ngIf="emailSendResult">
					<div *ngIf="emailSendResult.failures?.length">
						<h1>Falhas no envio({{ emailSendResult.failures.length }}):</h1>
						<br />
						<ul>
							<li *ngFor="let failure of emailSendResult.failures">
								{{ failure.venueName }}: {{ failure.message }}
							</li>
						</ul>
					</div>
					<br />
				</div>
				<br />
				<div *ngIf="emailSendResult.success?.length">
					<h1>Emails enviados com sucesso({{ emailSendResult.success.length }}):</h1>
					<br />
					<ul>
						<li *ngFor="let success of emailSendResult.success">
							{{ success.venueName }}: {{ success.message }}
						</li>
					</ul>
				</div>

				<div *ngIf="shouldShowRetry()">
					<button
						class="report-export__form__button button"
						style="margin: 25px 0"
						(click)="openRetryModal()"
					>
						Tentar novamente emails com falha
					</button>
				</div>
			</div>
		</div>
		<div class="boxTableReport">
			<div
				style="border: 4px solid red; margin: 8px"
				*ngIf="
					selectedOption.slug === 'nota-fiscal-venue' &&
					reportWithoutGroup &&
					reportWithoutGroup.length > 0
				"
			>
				<h1 style="font-size: 24px; text-align: center; margin: 8px">
					Venues sem configuração de grupo/envio
				</h1>
				<app-general-table [info]="reportWithoutGroup"></app-general-table>
			</div>
			<app-general-table
				[info]="reportFiltered"
				*ngIf="selectedOption.slug === 'nota-fiscal-venue'"
			></app-general-table>
			<app-general-table
				[info]="reportGiftCoverted"
				[headersDescription]="{
					'Valor do Gift':
						'Informação que aparece para o cliente no Gift, pode ser um valor monetário ou produto',
					'Valor Bruto Recebido': 'Valor que a Tagme recebe dos parceiro',
					'Valor Líquido Recebido': 'Valor que fica na Tagme.',
					'Valor de Repasse': 'Valor repassado à Venue'
				}"
				*ngIf="selectedOption.slug === 'emissao-gift'"
			></app-general-table>
		</div>
	</div>
</div>

<ch-modal [id]="modal.success" top="15%" [preventOutsideClickClose]="true">
	<section class="modal__wrapper">
		<div *ngIf="!isSendingEmail">
			<h1 class="titleModal">Confirma o envio de email com essas informações:</h1>
			<p style="margin-top: 20px">
				<strong>Mês:</strong>{{ monthYear }} <br /><br />
				<strong>Data limite para envio Nota:</strong> {{ dateLimit | date : 'dd/MM/yyyy'
				}}<br /><br />
				<strong>Data repasse:</strong> {{ endDate }}<br /><br />
				<strong>Total Venues:</strong> {{ report?.length }}<br /><br />
				<strong
					>Período: {{ form.controls.startDate.value | date : 'dd/MM/yyyy' }} a
					{{ form.controls.endDate.value | date : 'dd/MM/yyyy' }}
				</strong>
			</p>
			<button class="modal__btn" (click)="sendFinancialEmails()">Confirmar Envio</button>
			<button class="modal__btn modal__btn--ghost" (click)="closeModal()">Não</button>
		</div>
		<div *ngIf="isSendingEmail">
			<app-loading class="loading" slug="001"></app-loading>
		</div>
	</section>
</ch-modal>

<ch-modal [id]="modal.retry" top="15%" [preventOutsideClickClose]="true">
	<section class="modal__wrapper">
		<div *ngIf="!isSendingEmail">
			<h1 class="titleModal">Confirma o reenvio :</h1>
			<br /><br />
			<div *ngFor="let failure of emailSendResult?.failures">
				<p style="text-align: center" *ngIf="failure?.retry">{{ failure.venueName }}</p>
			</div>
			<!-- <button class="modal__btn" (click)="retryFailedEmails()">Confirmar Reenvio</button> -->
			<button class="modal__btn modal__btn--ghost" (click)="closeModal()">Não</button>
		</div>
		<div *ngIf="isSendingEmail">
			<app-loading class="loading" slug="001"></app-loading>
		</div>
	</section>
</ch-modal>
